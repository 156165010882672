import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AndOr,
  CollapsibleContent,
  CollapsibleTrigger,
  Eye,
  Subheading,
  Wrapper,
} from '../atoms/StyledShared';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { Expand } from '../icons/Expand';

export type CollapsibleProps = {
  label: React.ReactNode;
  indent?: boolean;
  children: ReactNode;
  and_or?: 'AND' | 'OR' | 'AND/OR';
  arrow?: boolean;
  open?: boolean;   //initially open?
  bold?: boolean;
};

export function Collapsible(props: CollapsibleProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(props.open);
  const toggleContent = () => setIsOpen(!isOpen);
  const and_or = props.and_or ? t(props.and_or.toLowerCase()) : '';

  return (
    <Wrapper>
      <CollapsibleTrigger onClick={toggleContent}>
        {props.and_or && (
          <Subheading bold={props.bold}>
            <AndOr>{and_or}</AndOr>
          </Subheading>
        )}
        <Subheading
          bold={props.bold}
          indent={props.indent ? props.indent : false}>
          {props.label}&nbsp;&nbsp;
          {props.arrow ? (
            <Expand style={isOpen ? { transform: 'rotate(180deg)' }: {}} />
          ) : isOpen ? (
            <Eye>
              <FaRegEyeSlash />
            </Eye>
          ) : (
            <Eye>
              <FaRegEye />
            </Eye>
          )}
        </Subheading>
      </CollapsibleTrigger>
      <CollapsibleContent
        isOpen={isOpen}
        indent={props.indent ? props.indent : false}
      >
        {props.children}
      </CollapsibleContent>
    </Wrapper>
  );
}
