import { stringifyUrl } from 'query-string';
import { useQuery } from 'react-query';
import { useAPIBase } from './use-api-base';

type SearchParams = {
  searchValue?: string;
  page?: number;
  selectedFacets: { [key: string]: Set<string> };
  trigger: number;
};

export type SearchQueryResponse = {
  pagination: {
    page: number;
    totalPages: number;
    totalResults: number;
  };
  results: SearchResult[];
  facets: {
    lang?: FacetOption[];
    lang_type?: FacetOption[];
    form?: FacetOption[];
    lay_type?: FacetOption[];
    digitised?: FacetOption[];
    town?: FacetOption[];
    parish?: FacetOption[];
    barony?: FacetOption[];
    county?: FacetOption[];
    region?: FacetOption[];
    country?: FacetOption[];
    fenian?: FacetOption[];
    no_date?: FacetOption[];
    loc_type?: FacetOption[];
    archive?: FacetOption[];
    collection?: FacetOption[];
  },
  date_range: {
    start_date: string;
    end_date: string;
  }
};

export function useSearch(searchParams: SearchParams) {
  let facetQuery: string = '';
  for (const param of Object.keys(searchParams.selectedFacets))  {
    if (searchParams.selectedFacets[param]) {
      facetQuery = facetQuery + `&${param}=${Array.from(searchParams.selectedFacets[param]).join(",")}`
    }
  }
  const base = useAPIBase();
  return useQuery<SearchQueryResponse>(
    ['search', searchParams.selectedFacets, searchParams.searchValue, searchParams.page, searchParams.trigger], async () => {
    return fetch(
      stringifyUrl({
        url: `${base}/item/`,
        query: {
          page: searchParams.page,
          keywords: searchParams.searchValue,
        },
      }) + facetQuery
    )
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return null;
      })
      .then(data => {
        return data;
      });
  },
  {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
}
