import React, { useState, useEffect } from 'react';
import { Pagination } from '../components/Pagination';
import { Grid, GridColumn } from '../components/Grid';
import { PageContainer } from '../atoms/PageContainer';
import { BreadCrumbs } from '../atoms/BreadCrumbs';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocationQuery } from '../hooks/use-location-query';
import styled from 'styled-components';
import { SearchBoxSmall } from '../components/SearchBox';
import { useSearch } from '../hooks/useSearch';
import { useLocale } from '../hooks/use-locale';
import { SongStory } from '../components/SongStory';
import { stringify } from 'query-string';
import { Collapsible } from '../components/Collapsible';
import { FacetOptionsCheckboxes } from '../components/FacetOptionsCheckboxes';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {
  Title,
  TitleSpacer
} from '../atoms/StyledShared';

const SearchPageContainer = styled(Grid)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const DropDownContainer = styled.div`
  justify-content: space-between;
  width: 28%;
  display: flex;
  flex-direction: column;
  height: 50%;
  margin: 0.5rem 0rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ResultsContainer = styled.div`
  margin: 1rem 0;
  @media (min-width: 768px) {
    width: 70%;
  }
`;

const SearchTerm = styled.div`
  padding: 0.5rem 0;
`;

const ResultsTotal = styled.div`
  color: #666666;
  padding: 0.5rem 0;
`;

const SearchButtons = styled.span`
  margin-bottom: 1rem;
`;

const ClearButton = styled.div`
  margin-top: 0.5rem;
  margin-left: 0.5rem;
`;

const HrMargin = styled.hr`
  margin: 1em;
`;

const locale = useLocale();

export const SearchPage: React.FC = () => {
  // State
  const {
    lang,
    lay_type,
    form,
    form_subform,
    keywords,
    digitised,
    lang_type,
    fenian,
    start_date,
    end_date,
    town,
    parish,
    barony,
    county,
    region,
    country,
    archive,
    collection,
  } = useLocationQuery();
  const [searchValue, setSearchValue] = useState(keywords || '');
  const sf: { [index: string]: any } = {
    lang,
    lay_type,
    form,
    form_subform,
    digitised,
    lang_type,
    fenian,
    start_date,
    end_date,
    town,
    parish,
    barony,
    county,
    region,
    country,
    archive,
    collection,
  };
  const [page, setPage] = useState(1);
  const [trigger, setTrigger] = useState(0);
  //  selectedFacets are those that are actually sent to the API
  const [selectedFacets, setSelectedFacets] = useState(sf);
  // pendingFacets are our working state.
  const [pendingFacets, setPendingFacets] = useState(sf);

  useEffect(() => {
    const temp: { [index: string]: any } = {};
    if (lang && lang !== '') temp.lang = new Set().add(lang);
    if (lay_type && lay_type !== '') temp.lay_type = new Set().add(lay_type);
    if (form_subform && form_subform !== '')
      temp.form_subform = new Set().add(form_subform);
    if (form && form !== '')
      temp.form = new Set().add(form);
    if (digitised && digitised !== '') temp.digitised = new Set().add(digitised);
    if (lang_type && lang_type !== '') temp.langtype = new Set().add(lang_type);
    if (fenian && fenian !== '') temp.fenian = new Set().add(fenian);
    if (start_date && start_date !== '') temp.start_date = new Set().add(start_date);
    if (end_date && end_date !== '') temp.end_date = new Set().add(end_date);
    if (town && town !== '') temp.town = new Set().add(town);
    if (parish && parish !== '') temp.parish = new Set().add(parish);
    if (barony && barony !== '') temp.barony = new Set().add(barony);
    if (county && county !== '') temp.county = new Set().add(county);
    if (region && region !== '') temp.region = new Set().add(region);
    if (country && country !== '') temp.country = new Set().add(country);
    if (archive && archive !== '') temp.archive = new Set().add(archive);
    if (collection && collection !== '') temp.collection = new Set().add(collection);

    setPendingFacets(temp);
    setSelectedFacets(temp);
  }, []);

  // These are the params are passed into the useSearch hook...
  const { data } = useSearch({
    page,
    selectedFacets,
    searchValue,
    trigger
  });


  const hasAnyPlaceOptions = (
    data && data.facets && (
      data.facets.town || 
      data.facets.parish || 
      data.facets.barony ||
      data.facets.county ||
      data.facets.region ||
      data.facets.country
    )
  );

  const hasAnyPlaceOptionsSelected = (
    selectedFacets['town'] && selectedFacets['town'].size > 0 ||
    selectedFacets['parish'] && selectedFacets['parish'].size > 0 ||
    selectedFacets['barony'] && selectedFacets['barony'].size > 0 ||
    selectedFacets['county'] && selectedFacets['county'].size > 0 ||
    selectedFacets['region'] && selectedFacets['region'].size > 0 ||
    selectedFacets['country'] && selectedFacets['country'].size > 0
  );

  const { t } = useTranslation();

  function sortPlaceOptions(placeOptions: FacetOption[])  {
    //let unsorted: { [index: string]: any } = placeOptions;
    const sort_key = `label_${locale}`
    return placeOptions.sort(
      (a: { [index: string]: any } , b: { [index: string]: any }) => (a[sort_key] > b[sort_key]) ? 1 : -1
    )
  }

  //From the dates of the first and last results (chronologically), make year options that reflect the available data.
  function getYearRangeOptions(
    date_range: { start_date: string; end_date: string },
    mode: 'start' | 'end'
  ): FacetOption[] {
    if (!date_range.start_date || !date_range.end_date) return [];
    let start_year = parseInt(date_range.start_date.substring(0, 4));
    let end_year = parseInt(date_range.end_date.substring(0, 4));
    let year = start_year;
    let options = [];
    while (year <= end_year) {
      let yearStr = year.toString();
      let value = mode === 'start' ? `${yearStr}-01-01` : `${yearStr}-12-31`;
      // count is unused here, use -1 as placeholder
      options.push({ label_en: yearStr, value: value, count: -1 });
      year++;
    }
    // Return year options in descending order
    return options.reverse();
  }

  // If selected year falls outside range of years in the dropdown, adjust it so it can remain selected in the dropdown.
  function makeInRange(
    date: 'start_date' | 'end_date',
    yearRangeOptions: FacetOption[],
    mode: 'min' | 'max'       // check chosen year against the earliest | latest year of the results
  ): string {
    let selected_year = new String(pendingFacets[date] ? Array.from(pendingFacets[date])[0] : null);
    if (!yearRangeOptions.length) return '';
    switch (mode) {
      case 'min':
        let last_idx = yearRangeOptions.length - 1;   
        if (parseInt(selected_year.toString()) < parseInt(yearRangeOptions[last_idx].label_en)) {
          return yearRangeOptions[last_idx].value.toString(); //last idx contains earliest year
        }
        return selected_year.toString();
      case 'max':
        if (
          parseInt(selected_year.toString()) >
          parseInt(yearRangeOptions[0].label_en)
        ) {
          return yearRangeOptions[0].value.toString();        //0 contains the latest year
        }
        return selected_year.toString();
    }
  }

  // Update the currently stored selected facet params & value sets
  function update(
    param_name: string,
    value: any,
    checked: boolean,
    value_type: 'single' | 'multi'
  ) {
    let temp_facets = { ...pendingFacets };
    if (temp_facets[param_name] === undefined || value_type === 'single') {
      temp_facets[param_name] = new Set();
    }
    // Special case. Uncheck form_subform if unchecking form.
    // This is because form_subform is only valid from homepage.
    if (param_name==='form' && !checked)  {
      temp_facets['form_subform'].delete(value);
    }
    // update the set for the changed facet
    if (checked) {
      temp_facets[param_name].add(value);
    } else {
      temp_facets[param_name].delete(value);
    }

    // update state
    setPendingFacets(temp_facets);
    if (['town', 'parish', 'barony', 'county', 'region', 'country'].includes(param_name)) {
      // search immediately on selection of any Place field
      setSelectedFacets(temp_facets);
      setTrigger(trigger > 0 ? 0 : 1);
    }
  }

  function search() {
    // send our working copy of the facets to the api
    setSelectedFacets(pendingFacets);
    // hacky fix to make useQuery fire here only!
    setTrigger(trigger > 0 ? 0 : 1);
  }

  // Clear all selected facets
  function clear() {
    setSelectedFacets({});
    setPendingFacets({});
  }

  // Clear specific facet
  function clearSelected(facet: string)  {
    let temp_pending_facets = { ...pendingFacets };
    delete temp_pending_facets[facet];
    setPendingFacets(temp_pending_facets);
  }

  // Clears selected date range. Must search in order to bring back date range from results unfiltered by date.
  function clearDates() {
    let temp_facets = { ...pendingFacets };
    delete temp_facets['start_date'];
    delete temp_facets['end_date'];
    setPendingFacets(temp_facets);
  }

  // Combine 'form' and 'form_subform' choices
  function combineFormChoices(pendingFacets: any) {
    let combo = new Set<string>();
    if (pendingFacets && pendingFacets['form']) {
      for (const elem of pendingFacets['form']) {
        combo.add(elem)
      }
    }
    if (pendingFacets && pendingFacets['form_subform']) {
      for (const elem of pendingFacets['form_subform']) {
        combo.add(elem)
      }
    }
    return combo
  }

  return (
    <PageContainer>
      {data && (
        <>
          <BreadCrumbs>
            <Link to="/">{`${t('Home')}`}</Link>
            {'>'}
            <Link to="/search">{`${t('Search')}`}</Link>
          </BreadCrumbs>
          <TitleSpacer>
            <Title>{`${t('Search')}`}</Title>
          </TitleSpacer>
          <SearchPageContainer>
            <DropDownContainer>
              <SearchButtons>
                <ButtonGroup size="small" aria-label="small button group">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => search()}
                  >
                    {t('Refine search')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => clear()}
                  >
                    {t('Clear filters')}
                  </Button>
                </ButtonGroup>
              </SearchButtons>
              {data && data.facets && data.facets.digitised && (
                <FacetOptionsCheckboxes
                  key="chk_digitised"
                  param_name="digitised"
                  onChange={update}
                  options={data.facets.digitised}
                  mode="checkboxes"
                  show_counts={true}
                  placeholder=""
                  checked_list={
                    pendingFacets['digitised']
                      ? pendingFacets['digitised']
                      : new Set()
                  }
                />
              )}
              {data && data.facets && data.facets.lang_type && (
                <FacetOptionsCheckboxes
                  key="chk_lang_type"
                  param_name="lang_type"
                  onChange={update}
                  options={data.facets.lang_type}
                  mode="checkboxes"
                  show_counts={true}
                  placeholder=""
                  checked_list={
                    pendingFacets['lang_type']
                      ? pendingFacets['lang_type']
                      : new Set()
                  }
                />
              )}
              <HrMargin/>
              {data && data.facets && data.facets.lang && (
                <Collapsible
                  label={t('Language')}
                  arrow={true}
                  open={
                    selectedFacets['lang'] && selectedFacets['lang'].size > 0
                  }
                >
                  <FacetOptionsCheckboxes
                    key="chk_lang"
                    param_name="lang"
                    onChange={update}
                    options={data.facets.lang}
                    mode="checkboxes"
                    show_counts={true}
                    placeholder=""
                    checked_list={
                      pendingFacets['lang'] ? pendingFacets['lang'] : new Set()
                    }
                  />
                  <hr/>
                </Collapsible>
              )}

              {data && data.facets && data.facets.fenian && (
                <Collapsible
                  label={t('Fianna')}
                  arrow={true}
                  open={
                    selectedFacets['fenian'] &&
                    selectedFacets['fenian'].size > 0
                  }
                >
                  <FacetOptionsCheckboxes
                    key="chk_fenian"
                    param_name="fenian"
                    onChange={update}
                    options={data.facets.fenian}
                    mode="checkboxes"
                    show_counts={true}
                    placeholder=""
                    checked_list={
                      pendingFacets['fenian']
                        ? pendingFacets['fenian']
                        : new Set()
                    }
                  />
                  <hr/>
                </Collapsible>
              )}
              {data && data.facets && data.facets.form && (
                <Collapsible
                  label={t('Form')}
                  arrow={true}
                  open={
                    selectedFacets['form'] && selectedFacets['form'].size > 0 
                    || selectedFacets['form_subform'] && selectedFacets['form_subform'].size > 0
                  }
                >
                  <FacetOptionsCheckboxes
                    key="chk_form"
                    param_name="form"
                    onChange={update}
                    options={data.facets.form}
                    mode="checkboxes"
                    show_counts={true}
                    placeholder=""
                    checked_list={
                      combineFormChoices(pendingFacets)
                    }
                  />
                  <hr/>
                </Collapsible>
              )}
              {data && data.facets && data.facets.lay_type && (
                <FacetOptionsCheckboxes
                  param_name="lay_type"
                  onChange={update}
                  options={data.facets.lay_type}
                  mode="dropdown"
                  show_counts={true}
                  placeholder={t('Story/Song')}
                  checked_list={
                    pendingFacets['lay_type']
                      ? pendingFacets['lay_type']
                      : undefined
                  }
                />
              )}
              {
                <Collapsible
                  label={t('Date Range')}
                  arrow={true}
                  open={
                    selectedFacets['start_date'] || selectedFacets['end_date']
                  }
                >
                  <>
                    <FacetOptionsCheckboxes
                      param_name="start_date"
                      onChange={update}
                      options={
                        data ? getYearRangeOptions(data.date_range, 'start') : []
                      }
                      mode="dropdown"
                      placeholder={t('From')}
                      checked_list={
                        new Set<string>().add(
                          makeInRange(
                            'start_date',
                            data
                              ? getYearRangeOptions(data.date_range, 'start')
                              : [],
                            'min'
                          )
                        )
                      }
                    />
                    <FacetOptionsCheckboxes
                      param_name="end_date"
                      onChange={update}
                      options={
                        data ? getYearRangeOptions(data.date_range, 'end') : []
                      }
                      mode="dropdown"
                      placeholder={t('To')}
                      checked_list={
                        new Set<string>().add(
                          makeInRange(
                            'end_date',
                            data
                              ? getYearRangeOptions(data.date_range, 'end')
                              : [],
                            'max'
                          )
                        )
                      }
                    />
                    <hr/>
                  </>
                </Collapsible>
              }
              {hasAnyPlaceOptions && (
                <Collapsible
                  label={t('Location')}
                  arrow={true}
                  open={hasAnyPlaceOptionsSelected}
                >
                  <>
                    {data && data.facets && data.facets.country && (
                      <>
                        <FacetOptionsCheckboxes
                          param_name="country"
                          onChange={update}
                          options={sortPlaceOptions(data.facets.country)}
                          mode="dropdown"
                          show_counts={true}
                          placeholder={t('Country')}
                          checked_list={
                            pendingFacets['country']
                              ? pendingFacets['country']
                              : new Set()
                          }
                        />
                        <br/>
                      </>
                    )}
                    {data && data.facets && data.facets.region && (
                      <>
                        <FacetOptionsCheckboxes
                          param_name="region"
                          onChange={update}
                          options={sortPlaceOptions(data.facets.region)}
                          mode="dropdown"
                          show_counts={true}
                          placeholder={t('Province/Region/State')}
                          checked_list={
                            pendingFacets['region']
                              ? pendingFacets['region']
                              : new Set()
                          }
                        />
                        <br/>
                      </>
                    )}
                    {data && data.facets && data.facets.county && (
                      <>
                        <FacetOptionsCheckboxes
                          param_name="county"
                          onChange={update}
                          options={sortPlaceOptions(data.facets.county)}
                          mode="dropdown"
                          show_counts={true}
                          placeholder={t('County/Historic County')}
                          checked_list={
                            pendingFacets['county']
                              ? pendingFacets['county']
                              : new Set()
                          }
                        />
                        <br/>
                      </>
                    )}
                    {data && data.facets && data.facets.barony && (
                      <>
                        <FacetOptionsCheckboxes
                          param_name="barony"
                          onChange={update}
                          options={sortPlaceOptions(data.facets.barony)}
                          mode="dropdown"
                          show_counts={true}
                          placeholder={t('Barony/District/Island')}
                          checked_list={
                            pendingFacets['barony']
                              ? pendingFacets['barony']
                              : new Set()
                          }
                        />
                        <br/>
                      </>
                    )}
                    {data && data.facets && data.facets.parish && (
                      <>
                        <FacetOptionsCheckboxes
                          param_name="parish"
                          onChange={update}
                          options={sortPlaceOptions(data.facets.parish)}
                          mode="dropdown"
                          show_counts={true}
                          placeholder={t('Parish')}
                          checked_list={
                            pendingFacets['parish']
                              ? pendingFacets['parish']
                              : new Set()
                          }
                        />
                        <br/>
                      </>
                    )}
                    {data && data.facets && data.facets.town && (
                      <>
                        {<FacetOptionsCheckboxes
                          param_name="town"
                          onChange={update}
                          options={sortPlaceOptions(data.facets.town)}
                          mode="dropdown"
                          show_counts={true}
                          placeholder={t('Town/Village')}
                          checked_list={
                            pendingFacets['town']
                              ? pendingFacets['town']
                              : new Set()
                          }
                        />}
                        <br/>
                      </>
                    )}
                    <hr/>
                  </>
                </Collapsible>
              )}
              {data && data.facets && data.facets.archive && (
                <Collapsible
                  label={t('Archive')}
                  arrow={true}
                  open={
                    selectedFacets['archive'] &&
                    selectedFacets['archive'].size > 0
                  }
                >
                  <FacetOptionsCheckboxes
                    key="chk_archive"
                    param_name="archive"
                    onChange={update}
                    options={data.facets.archive}
                    mode="checkboxes"
                    show_counts={true}
                    placeholder=""
                    checked_list={
                      pendingFacets['archive']
                        ? pendingFacets['archive']
                        : new Set()
                    }
                  />
                  <hr/>
                </Collapsible>
              )}
              {data && data.facets && data.facets.collection && (
                <Collapsible
                  label={t('Collection')}
                  arrow={true}
                  open={
                    selectedFacets['collection'] &&
                    selectedFacets['collection'].size > 0
                  }
                >
                  <FacetOptionsCheckboxes
                    key="chk_collection"
                    param_name="collection"
                    onChange={update}
                    options={data.facets.collection}
                    mode="checkboxes"
                    show_counts={true}
                    placeholder=""
                    checked_list={
                      pendingFacets['collection']
                        ? pendingFacets['collection']
                        : new Set()
                    }
                  />
                  <hr/>
                </Collapsible>
              )}
            </DropDownContainer>
            <ResultsContainer>
            <Pagination
            page={page || 1}
            totalPages={data.pagination.totalPages}
            onSelect={setPage}
          />
              <GridColumn>
                <SearchBoxSmall onSearch={value => setSearchValue(value)} />
                {searchValue && searchValue.length > 0 && (
                  <SearchTerm>
                    {t('You searched for')}: <em>{searchValue}</em>
                  </SearchTerm>
                )}
                {data.pagination && (
                  <ResultsTotal>
                    {t('Results')} ({data.pagination.totalResults})
                  </ResultsTotal>
                )}
                {data?.results &&
                  data.results.map(result => (
                    <SongStory {...result} key={result.new_id} />
                  ))}
              </GridColumn>
            </ResultsContainer>
          </SearchPageContainer>
          <Pagination
            page={page || 1}
            totalPages={data.pagination.totalPages}
            onSelect={setPage}
          />
        </>
      )}
    </PageContainer>
  );
};
