import React from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';

import { FacetLabel } from './FacetOptionsCheckboxes.style';
import { Dropdown } from '../atoms/Dropdown';
import { useLocale } from '../hooks/use-locale';
import { getLocaleKeyVal } from '../utils/getLocaleKeyVal';

export type FacetOptionsProps = {
  param_name: string;
  onChange: Function;
  options: FacetOption[];
  mode?: 'checkboxes' | 'dropdown';
  checked_list: Set<string>;
  placeholder: string;
  show_counts?: boolean;
};

export function FacetOptionsCheckboxes({
  param_name,
  onChange,
  options,
  mode,
  checked_list,
  placeholder,
  show_counts,
}: FacetOptionsProps) {

  const locale = useLocale();
  const { t } = useTranslation();

  function getOptionName(option: FacetOption, show_counts?: boolean) {
    let name = getLocaleKeyVal(option, 'label', locale); // for options with multilingual data (form/lay etc) the relevant language option is shown
    if (name === 'None') name = t(name);
    if (show_counts) return t(name) + ' (' + option.count + ')';
    return t(name); // it will translate the option name if a translation exists. (language only)
  }

  if (mode == 'dropdown') {
    return (
      //include 'checked_list' in the key so it updates when its value changes
      <FormControl fullWidth={true} margin={'none'}>
        <Dropdown
          key={`dd${param_name}_${checked_list}`}
          placeholder={placeholder}
          options={options.map((option: FacetOption) => {
            return {
              id: param_name + (option.value ? option.value : 'None'),
              value: option.value ? option.value.toString() : '',
              name: getOptionName(option, show_counts),
            };
          })}
          onChange={(e: string) =>
            onChange(param_name, e ? e : null, true, 'single')
          }
          varient={'outlined'}
          allowNoneOption={true}
          selected={checked_list ? [...checked_list][0] : undefined}
          fixedWidth={300}
        />
      </FormControl>
    );
  } else {
    return (
      <FormControl component="fieldset" key={`fc${param_name}`}>
        <FormGroup>
          {options.map((option: FacetOption) => {
            return (
              <FacetLabel
                key={`fcl${option.value}`}
                control={
                  <Checkbox
                    className="facet"
                    color="primary"
                    checked={checked_list.has(option.value.toString())}
                  />
                }
                value={option.value}
                label={getOptionName(option, show_counts)}
                onChange={
                  (e: any) => onChange(
                    param_name,
                    e.target.value,
                    e.target.checked,
                    'multi'
                  )
                }
              />
            );
          })}
        </FormGroup>
      </FormControl>
    );
  }
}
