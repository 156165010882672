// hooks/use-page-detail.tsx
import { useState, useEffect } from 'react';
import { useAPIBase } from './use-api-base';

export const usePageDetail = (slug: string, langCode: string) => {
  const base = useAPIBase();
  const [content, setContent] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPageData = async () => {
      const url = `${base}/pages/resources/${slug}/?locale=${langCode}`;
      console.log("FETCHING DETAIL --- " + url);
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Unable to fetch page data with requested title.');
        }
        const data = await response.json();
        setContent(data);
        setError(null);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPageData();
  }, [base, slug, langCode]);

  return { content, loading, error };
};
