import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/use-locale';
import { getPlaceNamePriority } from '../utils/getPlaceNamePriority';
import {
  DisplayCardContainerLinkResults,
  DisplayCardTitle,
} from './DisplayCard';
import { PaddingComponent } from '../atoms/PaddingComponent';
import { Grid, GridColumn } from './Grid';
import { Id, SongStoryTitleContainer } from './SongStory.style';
import { TileDetail } from './TileDetail';

export type SearchResultProps = SearchResult;

export function SongStory({
  new_id,
  title,
  people,
  place_of_collection,
  date,
  start_date_range,
  end_date_range,
  collection_name,
  institution_name
}: SearchResultProps) {
  const locale = useLocale();
  const { t } = useTranslation();

  function listOfPeoplesNames(peopleInRoles: PersonInSearchResult[]): string {
    return peopleInRoles
      .map(person => {
        if (!person.canonical_name && !person.name) return t("Unknown Person Name");
        return (
          person.canonical_name ||
          Object.keys(person.name)
            .filter(name => person.name[name].includes(locale))
            .join(', ')
        );
      })
      .join(', ');
  }

  return (
    <DisplayCardContainerLinkResults to={`/item/${new_id}`} target={"_blank"} rel={"noopener noreferrer"} >
      <SongStoryTitleContainer>
        <DisplayCardTitle>{title}</DisplayCardTitle>
        <Id>ID: {new_id}</Id>
      </SongStoryTitleContainer>
      <PaddingComponent/>
      <Grid>
        <GridColumn>
          {people.interviewee && (
            <TileDetail label={t('Interviewee')}>
              {listOfPeoplesNames(people.interviewee)}
            </TileDetail>
          )}
          {people.collector && (
            <TileDetail label={t('Collector')}>
              {listOfPeoplesNames(people.collector)}
            </TileDetail>
          )}
          {place_of_collection && (
            <TileDetail label={t('Location')}>
              {[
                ...getPlaceNamePriority(place_of_collection, locale),
                place_of_collection?.country?.country_name_en,
              ].join(', ')}
            </TileDetail>
          )}
          {(date || start_date_range) && (
            <TileDetail label={t('Date')}>
              {date || `${start_date_range} - ${end_date_range}`}
            </TileDetail>
          )}
          {institution_name && (
            <TileDetail label={t('Archive')}>{institution_name}</TileDetail>
          )}
          {collection_name && (
            <TileDetail label={t('Collection')}>{collection_name}</TileDetail>
          )}
        </GridColumn>
      </Grid>
    </DisplayCardContainerLinkResults>
  );
}
