import styled from 'styled-components';
import React from 'react';

export const Footer = styled.footer`
   {
    font-size: 12px;
    background-color: #0d47a1;
    color: #ffffff;
    margin: auto;
    bottom: 0;
  }
`;

export const FooterText = styled.p`
   {
    padding: 1rem 1.375rem;
    display: inline-block;
  }
`;

const FooterImg = styled.img.attrs(props => ({
  src:props.src,
  alt:props.alt
}))`
   {
     float: left;
     max-height: 74px;
     max-width: 200px;
     vertical-align: middle;
     padding: 1.3rem;
   }
`
const FooterImageContainer = styled('div')`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
`;

interface FooterImgProps {
  src: string;
  alt: string;
}
function FooterImage(props: FooterImgProps) {
  return (
    <FooterImageContainer>
      <FooterImg src={props.src} alt={props.alt}/>
    </FooterImageContainer>
  );
}

export {FooterImage}
