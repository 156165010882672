import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const AndOr = styled.div`
  font-style: italic;
`;

export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export const CollapsibleTrigger = styled.span`
  cursor: pointer;
  user-select: none;
`;

type CollapsibleContentProps = {
  isOpen?: boolean;
  indent: boolean;
}

export const CollapsibleContent = styled.div<CollapsibleContentProps>`
  ${props => props.isOpen ? 'display: block;' : 'display: none;'}
  ${props => props.indent ? 'margin-left: 1rem;' : 'margin-left: 0;'}
`;

export const ExternalLinksContainer = styled.div`
  max-width: 24rem;
  margin-bottom: 2rem;
`;

export const Eye = styled.span`
  margin-bottom: 0;
  margin-top: 0.15rem;
`;

export const GroupedData = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
`;

export const IndentedInline = styled.span`
  margin-left: 1rem;
`;

export const ItemDetailsContainer = styled.div`
  padding-top: 0;
  max-width: 60%;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const ItemPage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const KeyValuePair = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
`;

export const Label = styled.div`
  font-size: 1rem;
  color: #8a8a8a;
  text-decoration: rgb(97, 97, 97);
  line-height: 1.5rem;
  flex-basis: 50%;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 450;
  vertical-align: top;
  @media (max-width: 768px) {
    margin-right: 0.5rem;
  }
  @media (max-width: 576px) {
    margin-right: 0.35rem;
  }
  @media (max-width: 500px) {
    margin-bottom: 0;
    flex-basis: 100%
  }
`;

export const Label30 = styled(Label)`
  flex-basis: 30%;
  @media (max-width: 500px) {
    margin-bottom: 0;
    flex-basis: 100%
  }
`;

type SubheadingProps = {
  bold?: boolean;
  indent?: boolean;
}

export const Subheading = styled.div<SubheadingProps>`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #616161;
  ${props => props.bold ? 'font-weight: 800;' : 'font-weight: 550;'}
  display: flex;
  align-items: center; /* Ensures child elements are on the same horizontal line */
  ${props => props.indent ? 'margin-left: 1rem;' : 'margin-left: 0;'}
`;

export const SubheadingInline = styled.span`
  color: #616161;
  font-weight: 800;
`;

export const SubheadingInside = styled.div`
  color: #616161;
  font-weight: 800;
`;

export const TextLink = styled(Link)`
  text-decoration: none;
  color: black;
  cursor: pointer;
  text-decoration: underline;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 525;
  color: #5151515;
  margin-bottom: 1rem;
  padding-bottom: 0;
  margin-top: 0;
  padding-top: 0;
`;

export const TitleSpacer = styled.div`
  margin-top:1rem;
`

export const Value = styled.div`
  font-size: 1rem;
  color: #343a40;
  text-decoration: rgb(52, 58, 64);
  line-height: 1.5rem;
  vertical-align: top;
  margin-bottom: 0.5rem;
  padding-right: 0.35rem;
  flex-basis: 50%;
  @media (max-width: 768px) {
   padding-right: 0;
  }
  @media (max-width: 500px) {
    flex-basis: 100%
  }
`;

export const Value70 = styled(Value)`
  flex-basis: 70%;
  @media (max-width: 500px) {
    flex-basis: 100%
  }
`;


export const Quill = styled.div`
  & img {
    float: left;
    padding: 0.5rem;
  }
  & h1  {
    font-size: 2rem;
    font-weight: normal;
  }
  & h2 {
    font-size: 1.5rem;
    font-weight: 525;
  }
  & h3 {
    font-size: 1.25rem;
    font-weight: normal;
  }
  & p {
    margin: 0;
    padding: 0;
  }
  & a {
    color: #2c80e8 !important;
  }
  & blockquote {
    margin: 0;
    padding-left: 1rem;
    font-size: 1rem;
    font-style: italic;
    font-weight: bold;
    border-left: solid 4px #666;
  }
  & blockquote span {
    color: #666 !important;
  }
  & blockquote span:before  {
    content: open-quote;
  }
  & blockquote span:after  {
    content: close-quote;
  }
`;

export const QuillFw = styled(Quill)`
  color: #343a40;
  margin-bottom: 0.5rem;
`;


export const Wrapper = styled.div`

`;

type WrapperBgProps = {
  indent?: boolean;
}

export const WrapperBg = styled.div<WrapperBgProps>`
  background-color: #edf4fd;
  border-radius: 0.5rem;
  padding: 0.5rem;
  padding-left:1rem;
  border: solid #eeeeee 1px;
  ${props => props.indent ? 'margin-left: 1rem;' : 'margin-left: 0;'}
`;











