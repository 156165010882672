// hooks/use-pages.ts
import { useState, useEffect } from 'react';
import { useAPIBase } from './use-api-base';

export const usePages = (langCode: string) => {
  const base = useAPIBase();
  const [resources, setResources] = useState([]);

  useEffect(() => {
    const fetchPageListData = async () => {
      const localeQueryParam = `?locale=${langCode}`;
      var langslug = ``;
      if (langCode != 'en') {
        langslug = `-${langCode}`;
      }
      const listUrl = `${base}/pages/resources${langslug}/${localeQueryParam}`;
      console.log("FETCHING LIST --- " + listUrl);
      try {
        const response = await fetch(listUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log("DATA IS: ");
        console.log(data.child_pages);
        if (data && data.child_pages) {
          setResources(data.child_pages);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchPageListData();
  }, [base, langCode]);

  return resources;
};
